<template>
  <div class="container">
<!--    <h3>Элемент анкеты</h3>-->
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>

    <b-tabs>
      <b-tab title="Элемент анкеты">
        <b-form @submit.prevent="onSubmit">

          <b-form-group class="pt-2" id="checkListName" label-for="checkListName" label="Название" label-cols="3">
            <b-form-input id="checkListName" type="text" required  v-model="checkListLocal.checkListName" autocomplete="off" size="256"></b-form-input>
          </b-form-group>

          <b-form-group id="checkListCode" label-for="checkListCode" label="Код" label-cols="3">
            <b-form-input id="checkListCode" type="text" required  v-model="checkListLocal.checkListCode" autocomplete="off" size="16"></b-form-input>
          </b-form-group>

          <b-form-group id="genderCode" label-for="genderCode" label="Пол" label-cols="3">
            <b-form-select id="genderCode" v-model="checkListLocal.genderCode" :options="genderOptions" :select-size="genderOptions.length"></b-form-select>
          </b-form-group>

          <b-form-group id="checkListType" label-for="checkListType" label="Тип элемента анкеты" label-cols="3">
            <b-form-select id="checkListType" v-model="checkListLocal.checkListType" :options="checkListTypeOptions" :select-size="1"></b-form-select>
          </b-form-group>

          <b-form-group id="Скрыт для врача" label-for="hideForDoctor" label="" >
            <b-form-checkbox id="hideForDoctor" switch v-model="checkListLocal.hideForDoctor">Скрыт для врача
            </b-form-checkbox>
          </b-form-group>

          <div v-if="checkListLocal.checkListType === 2">
            <label class="editLabel" for="checkListGroups">Анкеты</label>
            <br>
            <treeselect name="checkListGroups" id="checkListGroups" placeholder=""
                        multiple clearable searchable
                        open-on-click disableFuzzyMatching
                        :noResultsText="'Не найдено...'"
                        :options="checkListGroupOptions"
                        :max-height="200"
                        :normalizer="normalizeGroupTreeselect"
                        v-model="checkListGroups" />
          </div>
          <br>
          <br>
          <div>
            <CheckListParamsEdit v-if="checkListLocal.checkListId" v-bind:check-list-id="checkListLocal.checkListId" ref="сheckListParamsEdit"></CheckListParamsEdit>
          </div>
          <br>
          <br>

          <b-button type="submit" class="btn-info" >Сохранить</b-button>

        </b-form>

      </b-tab>
      <b-tab v-if="checklist" title="Привязка патологий">
        <CheckListDiseaseList v-bind:checklist-id="checkListLocal.checkListId" ></CheckListDiseaseList>

      </b-tab>
    </b-tabs>


  </div>
</template>

<script>
import {host} from "@/constants";
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import CheckListParamsEdit from "@/components/admin/checklist/CheckListParamsEdit";
import CheckListDiseaseList from "./CheckListDiseaseList";
export default {
  name: "CheckListEdit",
  props: {
    checklist: Object
  },
  components: {
    CheckListDiseaseList,
    CheckListParamsEdit,
    Treeselect
  },
  data() {
    return {
      error: null,
      checkListLocal: this.checklist ? this.checklist : {
        checkListId: null,
        checkListName: null,
        checkListCode: null,
        hideForDoctor: false,
        genderCode: 0,
        checkListType: null,
        checkListTypeName: null

      },
      params: [],
      checkListGroups: [],
      checkListGroupOptions: [],
      checkListTypeOptions: [],
      paramOptions: [],
      genderOptions: [
        {
          value: 0,
          text: "Мужской и Женский"
        }, {
          value: 1,
          text: "Мужской"
        }, {
          value: 2,
          text: "Женский"
        }
      ],
      normalizeTreeselect(node) {
        return {
          id: node.paramId,
          label: node.paramName + (node.biomaterialTypeName ? ', ' + node.biomaterialTypeName : '') + ' ' +  node.paramCode
        }
      },
      normalizeGroupTreeselect(node) {
        return {
          id: node.value,
          label: node.text
        }
      },
    }
  },
  mounted() {
    this.fetchDictsData()
    this.fetchAdditionalData()
  },
  methods: {
    updateCheckListParams() {
      fetch(host + '/rest/checklist/' + this.checkListLocal.checkListId + '/params', {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({params:this.params}),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {

              this.$router.push('/checklists')

            } else {

              this.error = json.resultCode + ' ' + json.message;
            }
          })
          .catch(error => {
            this.error = error

          })
    },
    updateCheckListGroups() {
      fetch(host + '/rest/checklist/' + this.checkListLocal.checkListId + '/groups', {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({groups:this.checkListGroups}),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {


              //this.$emit('save-success')

              this.error = 'Сохранение выполнено успешно'

            } else {

              this.error = json.resultCode + ' ' + json.message;
            }
          })
          .catch(error => {
            this.error = error
          })
    },
    fetchAdditionalData() {
      if (this.checkListLocal.checkListId) {
        fetch(host + '/rest/checklist/' + this.checkListLocal.checkListId + '/groups', {
          credentials: "include"
        })
            .then(response => response.json())
            .then(json => {
              this.checkListGroups = json.groups
              this.loading = false
            })
        fetch(host + '/rest/checklist/' + this.checkListLocal.checkListId + '/params', {
          credentials: "include"
        })
            .then(response => response.json())
            .then(json => {
              this.params = json.params
              this.loading = false
            })
      }
    },
    fetchDictsData() {
      fetch(host + '/rest/dicts/checklistgroup', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.checkListGroupOptions = json.options
            this.loading = false
          })

      fetch(host + '/rest/dicts/checklisttype', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.checkListTypeOptions = json.options
            this.loading = false
          })
    },
    onSubmit() {
      let method = 'POST'
      if (this.checkListLocal.checkListId) {
        method = 'PUT'
      }


      fetch(host + '/rest/checklist', {
        method: method,
        credentials: 'include',
        body: JSON.stringify(this.checkListLocal),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {

              if (method === 'POST') {
                this.checkListLocal.checkListId = json.entityId
              }
              this.updateCheckListGroups()
              this.$refs.сheckListParamsEdit.onSubmitCheckListParams()

              if (method === 'PUT') {
                this.$emit('save-success')
              }
            } else {

              this.error = json.resultCode + ' ' + json.message;
            }
          })
          .catch(error => {
            this.error = error

          })





    }
  }
}
</script>

<style scoped>

</style>