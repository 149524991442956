<template>
  <div>
    <b-row>
      <b-col>
        Аналиты
          <b-button class="btn-info" @click="addNew()">
            <b-icon icon="plus" aria-hidden="true"></b-icon>
            Добавить
          </b-button>
      </b-col>
    </b-row>
    <b-row v-for="(measure, index) in measurementParams" :key="measure.value" class="row1remBottom">
      <b-col cols="9">
        <treeselect name="childrenParams" id="childrenParams" placeholder=""
                    searchable
                    open-on-click disableFuzzyMatching
                    :noResultsText="'Не найдено...'"
                    :options="paramOptions"
                    :max-height="200"
                    :normalizer="normalizeTreeselect"
                    v-model="measure.paramId" />
      </b-col>

      <b-col cols="2">
        <b-form-select id="checkListParamState" v-model="measure.checkListParamState" :options="checkListParamStateOptions" :select-size="1"></b-form-select>
      </b-col>
      <b-col cols="1">
        <b-button class="btn-info" @click="deleteParamMeasurement(checkListId, measure, index)">
          <b-icon icon="x-circle" aria-hidden="true"></b-icon>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {host} from "@/constants";
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  name: "CheckListParamsEdit",
  props: ["checkListId"],
  components: {
    Treeselect
  },
  data() {
    return {
      measurementParams: [],

      error: null,
      paramOptions: [],
      checkListParamStateOptions: [
        {value: 1, text: "базовый"},
        {value: 2, text: "уточняющий"}
      ],
      normalizeTreeselect(node) {
        return {
          id: node.paramId,
          label: node.paramCode + ' ' + node.paramName + (node.biomaterialTypeName ? ', ' + node.biomaterialTypeName : '')
        }
      }
    }
  },
  mounted: function () {
    this.fetchData()
    this.fetchDicts()
  },
  methods: {
    addNew() {
      this.measurementParams.push({paramId: null, checkListParamState: 1})
    },
    fetchDicts() {
      fetch(host + '/rest/params', {
        credentials: "include"
      }).then(response => response.json())
          .then(json => {
            this.paramOptions = json.params
          })

    },
    fetchData() {
      fetch(host + '/rest/checklist/' + this.checkListId + '/params', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.measurementParams = json.params
          })
    },
    fetchDelete(paramId, value) {
      fetch(host + '/rest/checklist/' + paramId + '/params/' + value, {
        method: 'DELETE',
        credentials: 'include',
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {
              console.log('Delete Success ' + json)
            } else {
              console.log('Delete Failed ' + json)
              this.error = json.message;
            }
          })
    },
    onSubmitCheckListParams() {
      for (var m of this.measurementParams) {
        console.log(JSON.stringify(m))
        let method = 'POST'
        if (m.checkListParamId) {
          method = 'PUT'
        }
        let rq = m

        console.log(JSON.stringify(rq))
        fetch(host + '/rest/checklist/' + this.checkListId + '/params', {
          method: method,
          credentials: 'include',
          body: JSON.stringify(rq),
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                console.log('Save Success ' + JSON.stringify(json))
              } else {
                console.log('Login Failed ' + json)
                this.error = json.resultCode + ' ' + json.message;
              }
              if (method === 'POST') {
                m.checkListParamId = json.entityId
              }
            })
            .catch(error => {
              this.error = error
              console.log(JSON.stringify(error))
            })
      }
    },
    deleteParamMeasurement(paramId, measure, index) {
      if (confirm('Вы уверены, что хотите удалить данный Аналит?')) {
        if (measure.checkListParamId) {
          this.fetchDelete(paramId, measure.checkListParamId)
        }
        this.measurementParams.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped>

</style>